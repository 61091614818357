exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuelles-js": () => import("./../../../src/pages/aktuelles.js" /* webpackChunkName: "component---src-pages-aktuelles-js" */),
  "component---src-pages-geschichte-js": () => import("./../../../src/pages/geschichte.js" /* webpackChunkName: "component---src-pages-geschichte-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jugendvorstand-js": () => import("./../../../src/pages/jugendvorstand.js" /* webpackChunkName: "component---src-pages-jugendvorstand-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-mitgliedschaft-js": () => import("./../../../src/pages/mitgliedschaft.js" /* webpackChunkName: "component---src-pages-mitgliedschaft-js" */),
  "component---src-pages-sportangebote-js": () => import("./../../../src/pages/sportangebote.js" /* webpackChunkName: "component---src-pages-sportangebote-js" */),
  "component---src-pages-trainingszeiten-js": () => import("./../../../src/pages/trainingszeiten.js" /* webpackChunkName: "component---src-pages-trainingszeiten-js" */),
  "component---src-pages-turnrat-js": () => import("./../../../src/pages/turnrat.js" /* webpackChunkName: "component---src-pages-turnrat-js" */),
  "component---src-pages-uebungsleiter-js": () => import("./../../../src/pages/uebungsleiter.js" /* webpackChunkName: "component---src-pages-uebungsleiter-js" */),
  "component---src-pages-vorstandsteam-js": () => import("./../../../src/pages/vorstandsteam.js" /* webpackChunkName: "component---src-pages-vorstandsteam-js" */),
  "component---src-templates-aktuelles-js": () => import("./../../../src/templates/aktuelles.js" /* webpackChunkName: "component---src-templates-aktuelles-js" */),
  "component---src-templates-sportangebote-js": () => import("./../../../src/templates/sportangebote.js" /* webpackChunkName: "component---src-templates-sportangebote-js" */)
}

